import React, { Component } from "react";

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Rte from "./Rte";

class StepOne extends Component {
   render() {
      return (
      <section>
         <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">物件編號*</label>
                  <input
                     type="text"
                     name="propertyID"
                     className="form-control"
                     id="inputGroupPrepend2"
                     aria-describedby="inputGroupPrepend2"
                     placeholder="A12"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">房東姓名*</label>
                  <input
                     type="text"
                     name="landlordName"
                     className="form-control"
                     placeholder="王老大"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">房客姓名*</label>
                  <input
                     type="text"
                     name="tenantName"
                     className="form-control"
                     placeholder="王小明"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">房客電話*</label>
                  <input
                     type="tenantPhone"
                     className="form-control"
                     id="inputGroupPrepend2"
                     aria-describedby="inputGroupPrepend2"
                     placeholder="(02)123-4567"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">房客Email*</label>
                  <input
                     type="email"
                     className="form-control"
                     id="inputGroupPrepend2"
                     aria-describedby="inputGroupPrepend2"
                     placeholder="baobao@gmail.com"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">工單來源*</label>
                    <div id="multiselect">
                      <DropdownMultiselect
                        options={["電話", "Email", "留言板"]}
                      />
                    </div>
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">工單處理位置*</label>
                    <div id="multiselect">
                      <DropdownMultiselect
                        options={["客廳", "房間", "廚房", "廁所", "公共區"]}
                      />
                    </div>
               </div>
            </div>
			<div className="col-lg-12 mb-2">
				<div className="form-group">
					<label className="text-label">情況描述＊</label>
					<div className="summernote">
							<Rte />
					</div>
				</div>
			</div>
		</div>
      </section>
      );
   }
}


export default StepOne;
