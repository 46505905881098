import React, { Component } from "react";
import { Button } from 'react-bootstrap';

// images
import logo from "../../../../images/logo.png";
import logoText from "../../../../images/logo-text.png";

class StepTwo extends Component {
  render() {
    var today = new Date();
    var date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    this.state = {
      currentDate: date
    }
  return (
    <section>
      <div className="row">
        <div className="col-lg-12">
              <div className="row mb-5">
                <div className="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <h6>屋件:</h6>
                  <div>
                    <strong>A12</strong>
                  </div>
                  <div>王老大</div>
                  <div>信義區忠孝東路5段10號</div>
                  <div>台北市</div>
                  <div>Email: landlord@gmail.com</div>
                  <div>電話: (02) 123-4567</div>
                </div>
                <div className="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <h6>房客:</h6>
                  <div>
                    {" "}
                    <strong>王小明</strong>{" "}
                  </div>
                  <div>Email: baobao@gmail.com</div>
                  <div>電話: (02) 345-6789</div>
                </div>
                <div className="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="brand-logo mb-3">
                        <img className="logo-abbr mr-2" src={logo} alt="" />
                        <img className="logo-compact" src={logoText} alt="" />
                      </div>
                      <span>
                        今日日期: {this.state.currentDate}<br />
                        建單日期: 3/21/2021<br />
                        <strong>工單來源: 電話</strong>{" "}<br />
                        <strong>處理位置: 廚房</strong>
                      </span>
                    </div>
                    <div className="col-sm-5">
                    	<div className="mb-2">
                    		<Button className='mr-2' variant='info btn-rounded btn-sm'>
                    			<span className='btn-icon-left text-info'>
                    				<i className='fa fa-print color-info' />
                    			</span>
                    			列印
                    		</Button>
                    	</div>
                    	<div className="mb-2">
                    		<Button className='mr-2' variant='warning btn-rounded btn-sm'>
                    			<span className='btn-icon-left text-warning'>
                    			<i className='fa fa-download color-warning' />
                    			</span>
                    			下載
                    		</Button>
                    	</div>
                    	<div className="mb-2">
                    		<Button className='mr-2' variant='danger btn-rounded btn-sm'>
                    			<span className='btn-icon-left text-danger'>
                    			<i className='fa fa-envelope color-danger' />
                    			</span>
                    			Email
                    		</Button>
                    	</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-2">
              	<div className="clearfix text-left">
              		<h4>情況描述</h4>
              		<p>為了更優質的營運環境，維持企業或組織的持續穩定運作，資料中心機房常伴隨著因應不同需求所需要的不定時變更、移動、新增、刪減…等管理行為。例如：一個資料中心的日常營運，管理人員時時刻刻面臨眾多IT設備須汰舊換新、維修、工作分配變更…等重要任務。傳統的管理方式不但效率不彰，且在人員調度交接時，容易造成資訊斷層，使得設備佈署狀況難以即時掌握。因此，一套井然有序的操作與管理流程實屬必要，因此，台達InfraSuite Manager工單管理(Work Order)功能模組於焉而生。</p>
                </div>
              </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-12 mb-2">
        	<span className="text-right">
        		*請確認資訊是否無誤
        	</span>
        	<br />
        </div>
    </div>
    </section>
  );
  }
};

export default StepTwo;
