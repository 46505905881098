import React from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";


/// Ticket
import Tickets from "./components/Ticket/Tickets";
import NewTicket from "./components/Ticket/New/New";

/// Tenant
import Tenants from "./components/Tenant/Tenant";
import NewTenant from "./components/Tenant/New/New";

/// Property
import Property from "./components/Property/Property";
import NewProperty from "./components/Property/New/New";


/// Dashboard
import Home from "./components/Ticket/Tickets";

const Markup = () => {
  const routes = [
  	
    /// Ticket
    { url: "tickets", component: Tickets },
    { url: "ticket-new", component: NewTicket },
    
    /// Ticket
    { url: "tenants", component: Tenants },
    { url: "tenant-new", component: NewTenant },
    
    /// Ticket
    { url: "properties", component: Property },
    { url: "property-new", component: NewProperty },
    
    /// Dashboard
    { url: "", component: Home },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${
          !path ? "right-profile" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </Router>
  );
};

export default Markup;
