import React, { Component } from "react";

// images
import profile_image from "../../../../images/profile/TaoTao.jpg";

import { Row, Col, Card } from "react-bootstrap";
import Rte from "./Rte";


import MetarialDateAndTime from "./MetarialDateAndTime";


class StepThree extends Component {
  render() {
    var today = new Date();
    var date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    this.state = {
      currentDate: date
    }
  return (
	<section>
    	<Row>
        <Col lg={12}>
        <Card>
        <Card.Body>
		<div className="row">
			<div className="col-lg-12">
              <div className="row mb-5">
                <div className="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <h6>屋件:</h6>
                  <div>
                    <strong>A12</strong>
                  </div>
                  <div>王老大</div>
                  <div>信義區忠孝東路5段10號</div>
                  <div>台北市</div>
                  <div>Email: landlord@gmail.com</div>
                  <div>電話: (02) 123-4567</div>
                </div>
                <div className="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <h6>房客:</h6>
                  <div>
                    {" "}
                    <strong>王小明</strong>{" "}
                  </div>
                  <div>Email: baobao@gmail.com</div>
                  <div>電話: (02) 345-6789</div>
                </div>
                <div className="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-sm-7">
                      <span>
                        今日日期: {this.state.currentDate}<br />
                        最後更新日期: 3/24/2021<br />
                        建單日期: 3/21/2021<br />
                        <strong>工單來源: 電話</strong>{" "}<br />
                        <strong>處理位置: 廚房</strong>
                      </span>
                    </div>
                    <div className="col-sm-5">
                      <div className="mb-3">
                      	<span>工單負責人:</span><br />
                        <img className="logo-compact" src={profile_image} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-2">
              	<div className="clearfix text-left">
              		<h4>情況描述</h4>
              		<p>為了更優質的營運環境，維持企業或組織的持續穩定運作，資料中心機房常伴隨著因應不同需求所需要的不定時變更、移動、新增、刪減…等管理行為。例如：一個資料中心的日常營運，管理人員時時刻刻面臨眾多IT設備須汰舊換新、維修、工作分配變更…等重要任務。傳統的管理方式不但效率不彰，且在人員調度交接時，容易造成資訊斷層，使得設備佈署狀況難以即時掌握。因此，一套井然有序的操作與管理流程實屬必要，因此，台達InfraSuite Manager工單管理(Work Order)功能模組於焉而生。</p>
                </div>
              </div>
        	</div>
      	</div>
    	
		</Card.Body>
		</Card>
		</Col>
        <Col lg={12}>
        <Card>
        <Card.Body>
		<div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">工單負責人*</label>
                  <input
                     type="text"
                     name="propertyID"
                     className="form-control"
                     id="inputGroupPrepend2"
                     aria-describedby="inputGroupPrepend2"
                     placeholder="陶晶瑩"
                     required
                  />
               </div>
            </div>
            <div className="col-xxl-6 col-md-6 mb-3">
               <div className="form-group">
                  <label className="text-label">處理日期時間*</label>
                  <div className="float-left"><MetarialDateAndTime /></div>
                </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">處理狀況*</label>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      id="sel1"
                    >
                      <option>未分配</option>
                      <option>已送件</option>
                      <option>已完成</option>
                    </select>
               </div>
            </div>
            <div className="col-xxl-6 col-md-6 mb-3">
               <div className="form-group">
                  <label className="text-label">處理公司或技工*</label>
                  <input
                     type="text"
                     name="tenantName"
                     className="form-control"
                     placeholder="王小明"
                     required
                  />
               </div>
            </div>
			<div className="col-lg-12 mb-2">
				<div className="form-group">
					<label className="text-label">備註＊</label>
					<div className="summernote">
							<Rte />
					</div>
				</div>
			</div>
		</div>
		</Card.Body>
		</Card>
		</Col>
        <Col lg={12}>
        <Card>
        <Card.Body>
		<div className="row">
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">是否回報房客*</label>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      id="sel2"
                    >
                      <option>是</option>
                      <option>否</option>
                    </select>
               </div>
            </div>
		</div>
		</Card.Body>
		</Card>
		</Col>
		</Row>
      </section>
   );
  }
};

export default StepThree;
