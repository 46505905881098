import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    var d = new Date();
    
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let ticket = [
      	"tickets",
        "ticket-new",
      ],
      tenant = [
      	"tenants",
        "tenant-new",
      ],
      property = [
      	"properties",
        "property-new",
      ];

    return (
      <div className="mainnav">
        <PerfectScrollbar className="mainnav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${ticket.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="true">
                <i className="flaticon-381-notepad"></i>
                <span className="nav-text">工單</span>
              </Link>
              <ul aria-expanded="true">
              	<li>
                  <Link className={`${path === "tickets" ? "mm-active" : ""}`} to="/tickets">
                    工單總覽
                  </Link>
              	</li>
              	<li>
                  <Link className={`${path === "ticket-new" ? "mm-active" : ""}`} to="/ticket-new">
                    新工單
                  </Link>
              	</li>
              </ul>
            </li>
            <li className={`${tenant.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-id-card-1"></i>
                <span className="nav-text">房客資料</span>
              </Link>
              <ul aria-expanded="true">
              	<li>
                  <Link className={`${path === "tenants" ? "mm-active" : ""}`} to="/tenants">
                    房客總覽
                  </Link>
              	</li>
              	<li>
                  <Link className={`${path === "tenant-new" ? "mm-active" : ""}`} to="/tenant-new">
                    新房客
                  </Link>
              	</li>
              </ul>
            </li>
            <li className={`${property.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-home"></i>
                <span className="nav-text">屋件資料</span>
              </Link>
              <ul aria-expanded="true">
              	<li>
                  <Link className={`${path === "properties" ? "mm-active" : ""}`} to="/properties">
                    屋件總覽
                  </Link>
              	</li>
              	<li>
                  <Link className={`${path === "property-new" ? "mm-active" : ""}`} to="/property-new">
                    新屋件
                  </Link>
              	</li>
              </ul>
            </li>
          </MM>

          <div className="copyright">
            <p>
              <strong>EZDomo</strong> © {d.getFullYear()} All Rights Reserved
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
