import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";

/// imge
import { Link } from "react-router-dom";

const TicketTable = () => {
  const chackbox = document.querySelectorAll(".bs_exam_topper input");
  const motherChackBox = document.querySelector(".bs_exam_topper_all input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="房客總覽" motherMenu="首頁" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>房客總覽</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width50 ">
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3 bs_exam_topper_all">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkAll"
                          required=""
                          onClick={() => chackboxFun("all")}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkAll"
                        ></label>
                      </div>
                    </th>
                    <th>
                      <strong>狀態</strong>
                    </th>
                    <th>
                      <strong>屋件ID</strong>
                    </th>
                    <th>
                      <strong>地址</strong>
                    </th>
                    <th>
                      <strong>工單負責人</strong>
                    </th>
                    <th>
                      <strong>建立日期</strong>
                    </th>
                    <th>
                      <strong>最後更新日期</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3 bs_exam_topper">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheckBox2"
                          required=""
                          onClick={() => chackboxFun()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckBox2"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Badge variant="danger">R</Badge>{" "}
                        <Badge variant="outline-warning">Y</Badge>{" "}
                        <Badge variant="outline-success">G</Badge>
                      </div>
                    </td>
                    <td>
                      <strong>A12</strong>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="w-space-no">台北市信義區忠孝東路5段10號</span>
                      </div>
                    </td>
                    <td>陶晶瑩</td>
                    <td>3/21/2021</td>
                    <td>3/24/2021</td>
                    <td>
                      <div className="d-flex">
                        <Link
                          href="#"
                          className="btn btn-primary shadow btn-xs sharp mr-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                          href="#"
                          className="btn btn-secondary shadow btn-xs sharp mr-1"
                        >
                          <i className="fa fa-print"></i>
                        </Link>
                        <Link
                          href="#"
                          className="btn btn-info shadow btn-xs sharp"
                        >
                          <i className="fa fa-file-pdf-o"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3 bs_exam_topper">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheckBox2"
                          required=""
                          onClick={() => chackboxFun()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckBox2"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Badge variant="outline-danger">R</Badge>{" "}
                        <Badge variant="warning">Y</Badge>{" "}
                        <Badge variant="outline-success">G</Badge>
                      </div>
                    </td>
                    <td>
                      <strong>A12</strong>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="w-space-no">台北市信義區基隆路一段333號5樓</span>
                      </div>
                    </td>
                    <td>陶晶瑩</td>
                    <td>3/22/2021</td>
                    <td>3/25/2021</td>
                    <td>
                      <div className="d-flex">
                        <Link
                          href="#"
                          className="btn btn-primary shadow btn-xs sharp mr-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                          href="#"
                          className="btn btn-secondary shadow btn-xs sharp mr-1"
                        >
                          <i className="fa fa-print"></i>
                        </Link>
                        <Link
                          href="#"
                          className="btn btn-info shadow btn-xs sharp"
                        >
                          <i className="fa fa-file-pdf-o"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    </Fragment>
  );
};

export default TicketTable;